<template>
  <div class="grid">
    <!-- TODO: Apagar comentários -->
    <!-- <div class="col-11 card-mobile">
      <Card>
        <template #title> Servidor</template>
        <template #content>
          <p><b>Nome Completo:</b> {{ store.dados.servidor.nome }}</p>
          <p><b>CPF:</b> {{ store.dados.servidor.cpf | mascaraCpf }}</p>
          <p><b>E-Mail:</b> {{ store.dados.servidor.email }}</p>
          <p><b>Telefone:</b> {{ store.dados.servidor.celular }}</p>
          <p>
            <b>RG:</b> {{ store.dados.servidor.rgNumero }} - <b>UF</b>:
            {{ store.dados.servidor.rgUf }}
          </p>
          <div class="row">
            <div class="col-10">
              <Tag
                :value="store.validacao.value"
                :severity="store.validacao.severity"
                :icon="store.validacao.icon"></Tag>
            </div>
            <div class="col-1">
              <Button
                title="Editar Servidor"
                icon="pi pi-pencil"
                class="p-button-sm p-button-warning"
                @click="abrirEditarServidor" />
            </div>
          </div>
        </template>
      </Card>
    </div> -->
    <!-- <div class="col-11 card-mobile">
      <Card>
        <template #content>
          <p>
            <b>Matrícula: </b>
            {{ store.dados.matricula.toString() | mascaraMatricula }}
          </p>
          <p><b>Entidade: </b> {{ store.dados.entidade.nome }}</p>
          <p>
            <b>Situação: </b>
            <span v-if="store.dados.vinculo">
              <span
                v-if="
                  store.dados.vinculo.id == 2 ||
                  store.dados.vinculo.id == 3 ||
                  store.dados.vinculo.id == 7
                "
                style="color: green">
                {{ store.dados.vinculo.nome }}
              </span>
              <span v-else style="color: red">
                {{ store.dados.vinculo.nome }}
              </span>
            </span>
          </p>

          <p v-if="store.dados.dataAdmissao">
            <b>Data de Admissão: </b
            >{{ store.dados.dataAdmissao | formatarData }}
          </p>
          <p
            v-if="
              (store.dados.afastado || store.dados.exonerado) &&
              store.dados.dataAfastamento
            ">
            <b>Data de Afastamento: </b
            >{{ store.dados.dataAfastamento | formatarData }}
          </p>
          <p><b>Município de Lotação: </b>{{ store.dados.municipioLotacao }}</p>
        </template>
      </Card>
    </div> -->
    <div class="col-12">
      <Card>
        <template #content>
          <Accordion :activeIndex="0">
            <AccordionTab>
              <template #header>
                <div
                  class="w-full flex flex-wrap justify-content-center md:justify-content-between align-items-center">
                  <div class="informacoes text-center">
                    <span
                      >Informações do Servidor - {{ store.dados.matricula }} -
                      {{ store.dados.servidor.nome }}</span
                    >
                  </div>
                  <div class="tag">
                    <Tag
                      :value="store.validacao.value"
                      :severity="store.validacao.severity"
                      :icon="store.validacao.icon" />
                  </div>
                </div>
              </template>
              <div class="flex flex-wrap">
                <div class="col-12 md:col-6">
                  <Card class="shadow-3 h-full">
                    <template #title>
                      <div class="flex justify-content-between">
                        Dados Pessoais
                        <Button
                          v-tooltip.bottom="'Editar'"
                          class="p-button-rounded p-button-outlined"
                          icon="pi pi-pencil"
                          aria-label="Editar Servidor"
                          size="small"
                          @click="abrirEditarServidor" />
                      </div>
                    </template>
                    <template #content>
                      <p>
                        Nome Completo: <b>{{ store.dados.servidor.nome }}</b>
                      </p>
                      <p>
                        CPF: <b>{{ store.dados.servidor.cpf | mascaraCpf }}</b>
                      </p>
                      <p>
                        E-Mail: <b>{{ store.dados.servidor.email }}</b>
                      </p>
                      <p>
                        Telefone: <b>{{ store.dados.servidor.celular }}</b>
                      </p>
                      <p>
                        <b>RG:</b> {{ store.dados.servidor.rgNumero }} -
                        <b>UF</b>:
                        {{ store.dados.servidor.rgUf }}
                      </p>
                    </template>
                  </Card>
                </div>
                <div class="col-12 md:col-6">
                  <Card class="shadow-3 h-full">
                    <template #title> Dados Profissionais </template>

                    <template #content>
                      <p>
                        Matricula: <b>{{ store.dados.matricula }}</b>
                      </p>
                      <p>
                        Entidade: <b>{{ store.dados.entidade.nome }} </b>
                      </p>
                      <p v-if="store.dados.vinculo">
                        Status:
                        <!-- id 2: PENSIONISTA, id 3: APOSENTADO, id 7: EFETIVO-->
                        <span
                          v-if="
                            store.dados.vinculo.id == 2 ||
                            store.dados.vinculo.id == 3 ||
                            store.dados.vinculo.id == 7
                          "
                          style="color: green">
                          {{ store.dados.vinculo.nome }}
                        </span>
                        <span v-else style="color: red">
                          {{ store.dados.vinculo.nome }}
                        </span>
                        <span v-if="!store.dados.vinculo">
                          TIPO DE VÍNCULO NÃO INFORMADO (VER CONTRACHEQUE)
                        </span>
                      </p>
                      <p v-if="store.dados.dataAdmissao">
                        Data de Admissão:
                        <b>{{ store.dados.dataAdmissao | formatarData }}</b>
                      </p>
                      <p v-if="store.dados.dataAfastamento">
                        Data de Afastamento:<b>{{
                          store.dados.dataAfastamento | formatarData
                        }}</b>
                      </p>
                      <p>
                        Município de Lotação:<b>{{
                          store.dados.municipioLotacao
                        }}</b>
                      </p>
                      <ProgressSpinner
                        v-if="loadingSpinner"
                        class="loading"
                        strokeWidth="5"
                        style="width: 60px; height: 60px" />
                      <div class="p-fluid formgrid grid">
                        <div
                          v-if="store.dados.afastado"
                          class="field col-6 md:col-3">
                          <span class="dados-matricula-label">Afastado:</span
                          ><span>{{
                            store.dados.afastado == true ? 'Sim' : 'Não'
                          }}</span>
                        </div>
                        <div
                          v-if="store.dados.exonerado"
                          class="field col-6 md:col-3">
                          <span class="dados-matricula-label">Exonerado:</span
                          ><span>{{
                            store.dados.exonerado == true ? 'Sim' : 'Não'
                          }}</span>
                        </div>
                        <div
                          v-if="store.dados.servidor.falecido"
                          class="field col-6 md:col-3">
                          <span class="dados-matricula-label">Falecido:</span
                          ><span>{{
                            store.dados.servidor.falecido == true
                              ? 'Sim'
                              : 'Não'
                          }}</span>
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </template>
      </Card>
    </div>
    <Dialog
      :visible.sync="servidorDialog"
      header="Atualizar Dados"
      :closeOnEscape="!saving"
      :closable="!saving"
      :modal="true"
      class="p-fluid">
      <fieldset :disabled="saving" class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="telefone">Telefone</label>
          <InputMask
            id="telefone"
            v-model.trim="servidor.celular"
            mask="(99)99999-9999" />
          <div
            v-if="submitted && v$.servidor.celular.required.$invalid"
            class="p-error">
            O campo Telefone é obrigatório.
          </div>
        </div>

        <div class="field col-12 md:col-6">
          <label for="mail">E-mail</label>
          <InputText v-model="servidor.email" />
          <div
            v-if="submitted && v$.servidor.email.required.$invalid"
            class="p-error">
            O campo E-mail é obrigatório.
          </div>
          <div
            v-if="submitted && v$.servidor.email.email.$invalid"
            class="p-error">
            E-mail é inválido.
          </div>
        </div>
      </fieldset>
      <template #footer>
        <Button
          :disabled="saving"
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="fecharEditarServidor" />
        <Button :disabled="saving" autofocus @click="salvarServidor">
          <span v-if="!saving" class="pi pi-check"></span>
          <span v-if="saving" class="pi pi-spin pi-spinner"></span>
          <span v-if="!saving" class="ml-2">Salvar</span>
          <span v-if="saving" class="ml-2">Aguarde</span>
        </Button>
      </template>
    </Dialog>
    <router-view />
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import MatriculaService from '@/service/MatriculaService.js'
import { servidorStore } from '@/stores/servidor'
import ServidorService from '@/service/ServidorService'

export default {
  setup() {
    const store = servidorStore()
    return { store, v$: UseVuelidate() }
  },

  data() {
    return {
      idMatricula: this.$route.params.id,
      servidorDialog: false,
      saving: false,
      submitted: false,
      servidor: {},
      loadingSpinner: false,
    }
  },

  validations() {
    return {
      servidor: {
        email: { required, email },
        celular: { required },
      },
    }
  },

  created() {
    this.servidorService = new ServidorService(this.$http)
    this.matriculaService = new MatriculaService(this.$http)
    this.store.$reset()
  },

  mounted() {
    this.carregarDadosServidor()
  },

  beforeDestroy() {
    this.store.$reset()
  },

  methods: {
    carregarDadosServidor() {
      this.loading = true
      this.loadingSpinner = true
      if (this.store.dados.id !== undefined && this.store.dados.id !== null) {
        this.idMatricula = this.store.dados.id
      }
      this.matriculaService
        .getMatriculaServidorLogado(this.idMatricula)
        .then((res) => {
          this.store.$reset()
          this.store.dados = res
          this.servidor = this.store.dados.servidor
          this.loading = false
          this.loadingSpinner = false
          this.store.checkValidation()
        })
        .catch((err) => {
          this.loading = false
          this.loadingSpinner = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })

          this.$router.replace({ name: 'matriculas' })
        })
    },

    abrirEditarServidor() {
      this.saving = false
      this.v$.$reset()
      this.servidorDialog = true
    },

    fecharEditarServidor() {
      this.saving = false
      this.v$.$reset()
      this.servidorDialog = false
    },

    salvarServidor() {
      if (this.validate()) {
        this.saving = true
        this.servidorService
          .atualizarServidor(this.idMatricula, this.servidor)
          .then(() => {
            this.fecharEditarServidor()
            this.carregarDadosServidor()
            this.$toast.add({
              severity: 'success',
              summary: 'Servidor atualizado com sucesso.',
              life: 10000,
            })
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
            this.saving = false
          })
      }
    },

    validate() {
      this.submitted = true
      this.v$.servidor.$touch()
      if (this.v$.servidor.$invalid) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  font-weight: bold;
}

.detalhes-servidor {
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.dados-matricula-label {
  margin-right: 5px;
  font-weight: bold;
}

.dados-servidor-label {
  margin-right: 5px;
  font-weight: bold;
  font-size: 1.5rem !important;
}

.dados-servidor {
  margin-right: 5px;
  font-size: 1.5rem !important;
}

.servidor-info {
  margin-bottom: 10px;
}

.p-tag {
  font-size: 1rem !important;
}

.facelido {
  background-color: red;
  color: white;
  margin-right: 5px;
  font-weight: bold;
  font-size: 1.5rem;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 1;
  left: 0;
  bottom: 1;
  right: 0;
}
</style>
